import React, { useEffect, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { Grid, Link, Paper, Theme, Typography, useMediaQuery } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import IconButton from 'components/common/IconButton';
import CircularTimer from 'components/common/CircularTimer';
import CelebrationIcon from 'components/svg/celebration.svg';
import { useTranslation } from 'i18n';

interface JLivePassportCreatedBannerProps {
  position?: string;
  top?: number;
  marginLeft?: number;
  onClose: () => void;
}

interface StyleProps {
  top?: number;
  marginLeft?: number;
  position?: string;
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const useStyles = makeStyles<StyleProps>()((theme: Theme, params) => ({
  root: {
    position: params?.position || 'absolute',
    ...(params?.top && { top: params.top }),
    ...(params?.marginLeft && { marginLeft: params.marginLeft }),
    marginRight: 64,
    padding: theme.spacing(2, 10, 2, 5.75),
    [theme.breakpoints.down('md')]: {
      ...(params?.marginLeft && { marginLeft: params.marginLeft / 2 }),
      marginRight: 32,
    },

    [theme.breakpoints.down('md')]: {
      ...(params?.marginLeft && { marginLeft: 4 }),
      marginRight: 4,
      zIndex: 1000,
    },
    [theme.breakpoints.down('sm')]: {
      ...(params?.marginLeft && { marginLeft: 4 }),
      marginRight: 4,
      padding: '32px 32px 40px 32px',
    },

    borderRadius: 16,
    boxShadow: '0px 8px 32px rgba(56, 60, 68, 0.16)',
    zIndex: 300,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(2),
    top: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      right: theme.spacing(1),
      top: theme.spacing(1),
    },
  },
  progress: {
    position: 'absolute',
    right: theme.spacing(2.5),
    bottom: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      right: theme.spacing(2.5),
      bottom: theme.spacing(2),
    },
  },
  textCenter: {
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
      fontSize: 24,
      fontWeight: 700,
    },
  },
}));

const JLivePassportCreatedBanner: React.FC<JLivePassportCreatedBannerProps> = (props) => {
  const { onClose, ...rest } = props;
  const { classes } = useStyles({ ...rest });
  const isSmallMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const [timer, setTimer] = useState(4);
  const [t] = useTranslation('user');

  useEffect(() => {
    const interval = setInterval(() => {
      setTimer((timer) => {
        if (timer > -1) return timer - 1;
        return timer;
      });
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    if (timer === -1) {
      onClose();
    }
  }, [timer, onClose]);

  return (
    <Paper
      id="jlive-passport-succes-banner"
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      className={classes.root}
    >
      <Grid container spacing={3} alignItems="center">
        <Grid
          item
          {...(isSmallMobile && {
            xs: 12,
            display: 'flex',
            justifyContent: 'center',
            style: { paddingLeft: 64 },
          })}
        >
          <CelebrationIcon />
        </Grid>
        <Grid item xs>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Typography
                variant="h5"
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                className={classes.textCenter}
              >
                {t('userProfile.userJLivePassportSuccessBanner.title')}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1">
                {t('userProfile.userJLivePassportSuccessBanner.subtitle')}{' '}
                <Link href="https://help.jlive.app/jlive-passport" underline="none">
                  {t('userSettings.learnMore')}
                </Link>
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <IconButton
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          className={classes.closeButton}
          onClick={onClose}
          size="large"
        >
          <CloseIcon />
        </IconButton>
        <CircularTimer
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          className={classes.progress}
          label={timer + 1}
          size={20}
          value={Math.ceil(timer * 20)}
        />
      </Grid>
    </Paper>
  );
};

export default JLivePassportCreatedBanner;
